import React from 'react'
import './hero-subtitle.css'

function HeroSubtitle() {
    return (
        <div>
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p>
        </div>
    )
}

export default HeroSubtitle
