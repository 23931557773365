import React from 'react'
import './hero-title.css'

function HeroTitle() {
    return (
        <div>
           <h1>Elegant textiles for an elegant lifestyle.</h1> 
        </div>
    )
}

export default HeroTitle
